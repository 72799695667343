import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/forpad/forpad/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ทีมเพื่อแพทย์  34-53, 175-188 `}</p>
    <h3 {...{
      "id": "ความเป็นมา"
    }}>{`ความเป็นมา`}</h3>
    <p>{`เป็นกลุ่มแพทย์และผู้สนับสนุนผู้สมัครกรรมการแพทยสภาในทีมเพื่อแพทย์ มุ่งหมายให้แพทย์ทำงานเพื่อสังคมได้อย่างมีความสุขโดยมีสมดุลของชีวิตตามควร ลดการตายในขณะปฏิบัติงานและหลังลงเวรของแพทย์จบใหม่ ฯ  `}</p>
    <p>{`มุ่งมั่นแก้ไขโดยการใช้ฐานอำนาจหน้าที่ของสภาแพทย์ หรือแพทยสภา ตาม พรบ.วิชาชีพเวชกรรม พ.ศ.๒๕๒๕ มาตรา ๗ (๖)
เพื่อเป็นเจ้าภาพในการเสนอให้มีมีกฎหมายเกี่ยวกับชั่วโมงการทำงานของแพทย์
รวมถึงให้เกิดการแก้ไขระบบบริหารจัดการงบประมาณและงานบริการทางการแพทย์และสาธารณสุขของประเทศ รวมแพทย์ ๓๔ คน
ลงสมัครเป็นผู้แทนแพทย์หรือกรรมการแพทยสภาในวาระ พ.ศ.๒๕๖๘-๒๕๗๐ ภายใต้ชื่อ ทีมเพื่อแพทย์
เป็นทีมที่มีแพทย์สมัครจำนวนครบกรรมการและมีจำนวนสูงที่สุดกว่าทีมเดิมๆซึ่งมีเพียง ๑๐ กว่าคน หรือไม่ถึง ๓๔ คน `}</p>
    <p>{`ทีมมีพัฒนาการมาจากทีมพลังแพทย์ซึ่งเคยมีแพทย์ในทีม ๓ คน เป็นกรรมการแพทยสภาในบางช่วง   `}</p>
    <p>{`ทีมนี้ มีนพ.ฐาปนวงศ์ ตั้งอุรไวรรณ จักษุแพทย์ แพทย์โรงพยาบาลพระนั่งเกล้า กระทรวงสาธารณสุข  เป็นหัวหน้าทีม`}<br parentName="p"></br>{`
`}{`และ พญ.อรพรรณ์ เมธาดิลกกุล อดีตผู้อำนวยกรสำนักกฎหมายการแพทย์และแพทย์ระบาดวิทยา เป็นผู้จัดการทีม `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      